import { styled } from 'styled-components';

export const GridWrapper = styled.section`
    padding: 40px 0;

    @media (max-width: 840px) {
        padding: 30px 0;
    }
`;

export const GridInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;

    @media (max-width: 840px) {
        gap: 40px;
    }
    @media (max-width: 580px) {
        gap: 25px;
    }
`;

export const GridRow = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 48px;

    @media (max-width: 1160px) {
        gap: 30px 20px;
    }
    @media (max-width: 1025px) {
        gap: 30px 12px;
    }
    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 30px 20px;
    }
    @media (max-width: 480px) {
        gap: 25px;
        justify-content: center;
    }
`;

export const GridItem = styled.div`
    width: calc(33.3% - 32px);
    max-width: 394px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    p {
        flex: 1;
    }
    @media (max-width: 1280px) {
        width: calc(33.3% - 20px);
    }
    @media (max-width: 1160px) {
        width: calc(33.3% - 15px);
    }
    @media (max-width: 1025px) {
        gap: 10px;
        width: calc(33.3% - 10px);
    }
    @media (max-width: 768px) {
        max-width: unset;
        width: calc(50% - 10px);
    }
    @media (max-width: 480px) {
        width: 100%;
        max-width: 350px;
    }
`;

export const AutoContainer = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 60px;

    @media (max-width: 1160px) {
        padding: 0 40px;
    }
    @media (max-width: 1025px) {
        padding: 0 30px;
    }
    @media (max-width: 940px) {
        padding: 0 25px;
    }
    @media (max-width: 768px) {
        padding: 0 15px;
    }
`;

// export default AutoContainer;
