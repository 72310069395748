import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileApp = () => {
    const navigate = useNavigate();

    const handleExploreClick = () => {
        navigate('/explore');
    };

    const handlePlanTripClick = () => {
        navigate('/trip');
    };

    return (
        <div className="landing">
            <h1>Travel Pal</h1>
            <button onClick={handleExploreClick}>Explore</button>
            <button onClick={handlePlanTripClick}>Plan Trip</button>
        </div>
    );
};

export default MobileApp;
