import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { useAuth } from '../contexts/UserContext';

import './MainMenu.css';

const MainMenu = () => {
    const { user, signOut } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => setIsOpen(false);

    const onSignOut = useCallback(async () => {
        closeMenu();
        await signOut();
    }, [signOut]);

    const handleMenuStateChange = useCallback((state) => {
        setIsOpen(state.isOpen);
    }, []);

    return (
        <Menu isOpen={isOpen} onStateChange={handleMenuStateChange}>
            <div className="bm-items-top">
                <Link onClick={closeMenu} to="/">
                    Home
                </Link>
                <Link onClick={closeMenu} to="/explore">
                    Explore
                </Link>
                <Link onClick={closeMenu} to="/trip">
                    Plan Trip
                </Link>
                <Link onClick={closeMenu} to="/profile">
                    Profile
                </Link>
            </div>
            <div className="bm-items-bottom">
                {user && (
                    <button onClick={onSignOut} style={{ width: 'auto' }}>
                        Log out
                    </button>
                )}
            </div>
        </Menu>
    );
};

export default MainMenu;
