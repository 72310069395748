import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/UserContext';
import { useLocation } from 'react-router-dom';
import LoadingPage from './mobile/LoadingPage';

const PrivateRoutes = () => {
    const { user, checkingSession, setOnLoginRedirect } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (user) {
            setOnLoginRedirect(null);
        } else {
            setOnLoginRedirect(location);
        }
    }, [location, user, setOnLoginRedirect]);

    if (checkingSession) {
        return <LoadingPage></LoadingPage>;
    }

    return user ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
