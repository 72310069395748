import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { backendPOST } from '../utils';
import { IoInformationCircle, IoImage } from 'react-icons/io5';
import LoadingDots from '../components/loadingdots';

import './ExploreDestinationCard.css';

const placeholder =
    'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=640&w=512&q=80';

// Note: imageUrl is possibly undefined
function ExploreDestinationCard({ name, description, imageUrl, bestTimeToVisit, activities, categories }) {
    const [showDescription, setShowDescription] = useState(false);
    const [loadingTrip, setLoadingTrip] = useState(false);

    const navigate = useNavigate();

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    const url = imageUrl ?? placeholder;

    const generateTrip = async () => {
        setLoadingTrip(true);
        try {
            const tripParameters = {
                location: name,
                budget: 1500,
                vibes: categories.categories.join(','),
                numberOfDays: 7,
                occasion: 'Exploring the earth',
            };
            const trip = await backendPOST(
                'generate-trip',
                { parameters: tripParameters },
                {
                    retryOnHerokuTimeout: true,
                    maxRetries: 5,
                }
            );
            navigate('/review-trip', { state: { trip: { ...trip, parameters: tripParameters } } });
        } finally {
            setLoadingTrip(false);
        }
    };

    return (
        <div className="explore-destination-card">
            {showDescription ? (
                <div className="description-container">
                    <div className="information">
                        <h3>
                            <IoImage onClick={toggleDescription} style={{ float: 'right' }} size={48} />
                            <span>{name}</span>
                        </h3>
                        <p>{description}</p>
                        <p>
                            <span className="highlight">{bestTimeToVisit}</span> | Best time to visit
                        </p>
                        <p className="highlight">Activities for you:</p>
                        <ul>
                            {activities.map((activity) => (
                                <li key={activity}>{activity}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="button-container">
                        <button onClick={generateTrip}>
                            {loadingTrip ? <LoadingDots displayState={true} /> : 'Create Trip!'}
                        </button>
                    </div>
                </div>
            ) : (
                <div onClick={toggleDescription}>
                    <img src={url} alt={name} className="main-image" />
                    <div className="icon-container">
                        <IoInformationCircle style={{ backgroundColor: 'white', borderRadius: '48px' }} size={48} />
                    </div>
                    <div className="image-footer">
                        <h3>{name}</h3>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExploreDestinationCard;
