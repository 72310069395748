import React, { useEffect } from 'react';
import { styled } from 'styled-components';

//placeholder Text Style
const MutedText = styled.span`
    font-size: 14px;
    color: $black;
    font-weight: 300;
    span {
        font-weight: 100;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const StyledInput = styled.div`
    width: 100%;
    font-size: 40px;

    input,
    textarea {
        width: 100%;
        background-color: transparent;
        resize: none;
        transition: 0.3s ease-out;
        font-family: 'Indie Flower', cursive;

        //font
        color: ${(props) => props.theme.black};
        font-style: normal;
        font-weight: 400;
        font-size: inherit;
        line-height: 1;
        letter-spacing: -0.4px;
        overflow: hidden;
        text-overflow: ellipsis;
        &::placeholder {
            color: var(--secondary);
            transition: 0.3s ease-out;
        }
        &:focus::placeholder {
            opacity: 0.4;
        }
    }
    textarea._overflow {
        overflow-y: auto;
        line-height: 1.4;
        // hide scroll
        scrollbar-width: none; /* Firefox 64 */
        -ms-overflow-style: none; /* Internet Explorer 11 */
        &::-webkit-scrollbar {
            /** WebKit */
            display: none;
        }
    }
    &.underline {
        input,
        textarea {
            border-bottom: 2px solid ${(props) => props.theme.black};
        }
        input {
            margin-bottom: 0.23em;
        }
        textarea {
            line-height: 1.2;
            min-height: 1.45em;
            padding-top: 0.18em;
        }
    }
    @media (max-width: 1280px) {
        font-size: 35px;
    }
    @media (max-width: 840px) {
        font-size: 30px;
    }
    @media (max-width: 580px) {
        font-size: 25px;
    }
    @media (max-width: 480px) {
        font-size: 22px;
    }
`;

export const InputUnderline = React.forwardRef(({ breakText = false, onChange, ...props }, ref) => {
    return (
        <StyledInput className="underline" onChange={onChange}>
            {!breakText && <input type="text" {...props} ref={ref} />}
            {breakText && <BreakkingInput {...props} />}
        </StyledInput>
    );
});

const BreakkingInput = (props) => {
    const ref = React.useRef();
    useEffect(() => {
        if (!ref?.current) return;
        const onChange = () => {
            const input = ref.current;
            input.style.height = '';
            const computedHeight = parseInt(window.getComputedStyle(input).height);

            if ((!input.style.height || input.style.height === '') && computedHeight < input.scrollHeight + 5) {
                input.style.height = `${input.scrollHeight + 5}px`;
            } else if (input.style !== '' && computedHeight >= input.scrollHeight) {
            } else {
                input.style = '';
            }
        };
        onChange();
        window.addEventListener('resize', onChange);
        ref.current.addEventListener('input', onChange);
        ref.current.addEventListener('change', onChange);

        return () => {
            window.removeEventListener('resize', onChange);
        };
    }, [ref]);

    return <textarea className="_overflow" rows="1" {...props} ref={ref} />;
};

export const InputWrapper = ({ children }) => {
    return <div className="input__wrapper">{children}</div>;
};

export const InputLabel = (props) => {
    const label = React.useRef();

    // onclick focus input
    useEffect(() => {
        if (label.current) {
            const parent = label.current.parentElement;

            let input = parent ? parent.querySelector('input') : null;
            input = input ?? parent.querySelector('textarea');

            if (parent && input && parent.classList.contains('input-wrapper')) {
                label.current.addEventListener('click', () => {
                    input.focus();
                });
            }
        }
    }, [label]);

    return <MutedText {...props} ref={label} />;
};

export const HeroRow = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 30px;
    width: 100%;

    .input__wrapper {
        &:nth-child(1) {
            max-width: 320px;
        }
        &:nth-child(2) {
            max-width: 100px;
            input {
                text-align: center;
            }
        }
        &:nth-child(3) {
            max-width: 120px;
        }
        &:nth-child(4) {
            max-width: 250px;
        }
        &:nth-child(5) {
        }
    }

    @media (max-width: 1280px) {
        flex-wrap: wrap;
        .input__wrapper {
            &:nth-child(1) {
                max-width: unset;
                width: calc(50% - 15px);
            }
            &:nth-child(2) {
                max-width: unset;
                width: calc(25% - 15px);
            }
            &:nth-child(3) {
                max-width: unset;
                width: calc(25% - 15px);
            }
            &:nth-child(4) {
                max-width: unset;
                width: calc(50% - 15px);
            }
            &:nth-child(5) {
                width: calc(50% - 15px);
            }
        }
        @media (max-width: 580px) {
            justify-content: center;
            max-width: 360px;
            margin: 0 auto;

            .input__wrapper:nth-child(n) {
                width: 100%;
                input {
                    text-align: start;
                }
                &:nth-child(2) {
                    max-width: unset;
                    width: calc(50% - 15px);
                    max-width: 165px;
                }
                &:nth-child(3) {
                    max-width: unset;
                    width: calc(50% - 15px);
                    max-width: 165px;
                }
            }
        }
        @media (max-width: 580px) {
            gap: 25px;
            justify-content: center;
        }
    }
`;
