import Masonry from 'react-responsive-masonry';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exploreImages } from '../config';
import { backendPOST } from '../utils';
import { Device, DeviceContext } from '../contexts/DeviceContext';
import './Explore.css';

// TODO(andy): Picking the first image of the group of 4. We need to think in the future how we want to do this, maybe
// randomizing per user on the server? Whatever we do I think we should make it so that any given user always sees the
// same images (and then maybe A/B test on different images?)
const baseImages = exploreImages.filter((_, index) => index % 4 === 0);

const GRID_CONFIG = {
    [Device.MOBILE.name]: { columnsCount: 2, imageWidth: 370 },
    [Device.TABLET.name]: { columnsCount: 3, imageWidth: 320 },
    [Device.DESKTOP.name]: { columnsCount: 4, imageWidth: 470 },
    [Device.BIG_SCREEN.name]: { columnsCount: 5, imageWidth: 800 },
};
const Explore = () => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [loadingDestinations, setLoadingDestinations] = useState(false);
    const { device } = useContext(DeviceContext);

    const columnsCount = device ? GRID_CONFIG[device].columnsCount : 2;
    const imageWidth = useMemo(() => (device ? GRID_CONFIG[device].imageWidth : 600), [device]);
    const images = useMemo(
        () =>
            baseImages.map((image) => {
                const { url, ...rest } = image;
                return { ...rest, url: `${url}?w=${imageWidth}` };
            }),
        [imageWidth]
    );

    const navigate = useNavigate();

    const handleImageClick = (imageId) => {
        setSelectedImages((prevSelectedImages) =>
            prevSelectedImages.includes(imageId)
                ? prevSelectedImages.filter((image) => image !== imageId)
                : [...prevSelectedImages, imageId]
        );
    };

    const handleViewDestinationsClick = async () => {
        setLoadingDestinations(true);
        try {
            const categories = selectedImages.map((imageId) => exploreImages[imageId].category);
            const { destinations } = await backendPOST(
                'get-destinations',
                { categories },
                {
                    retryOnHerokuTimeout: true,
                    maxRetries: 5,
                }
            );
            console.log(destinations);
            navigate('/review-explore-destinations', { state: { destinations, categories: categories } });
        } finally {
            setLoadingDestinations(false);
        }
    };

    return (
        <div className="explore-container">
            <h1>What's your vibe?</h1>
            <h2>Your journey starts here. Select images that best represent your style and personality.</h2>

            {/* TODO(andy): In theory Masonry supports a responsive config to change number of columns based
            on device size, but couldn't make it work out of the box*/}
            <Masonry className="masonry" columnsCount={columnsCount} gutter="10px">
                {images.map((image) => (
                    <img
                        key={image.id}
                        src={image.url}
                        alt={image.alt}
                        className={`grid-image ${selectedImages.includes(image.id) ? 'selected' : ''}`}
                        onClick={() => handleImageClick(image.id)}
                    />
                ))}
            </Masonry>
            <div className="bottom-section">
                <button
                    onClick={handleViewDestinationsClick}
                    disabled={loadingDestinations || selectedImages.length < 1}
                >
                    {selectedImages.length < 1 ? 'Select style' : loadingDestinations ? '...' : 'View Destinations'}
                </button>
            </div>
        </div>
    );
};

export default Explore;
