import { IoPersonCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';

const Avatar = () => {
    const navigate = useNavigate();

    const onAvatarClick = useCallback(() => {
        navigate('/profile');
    }, [navigate]);

    return <IoPersonCircleOutline size={48} style={{ color: 'white' }} onClick={onAvatarClick} />;
};

export default Avatar;
