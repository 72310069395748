import useSWR from 'swr';
import React, { useCallback } from 'react';
import { useAuth } from '../contexts/UserContext';
import { backendGET } from '../utils';

const UserProfile = () => {
    const { user, signOut, session } = useAuth();

    const { data } = useSWR(session ? '/bookmarked-destinations' : null, () =>
        backendGET('bookmarked-destinations', { session })
    );

    const onSignOut = useCallback(async () => {
        await signOut();
    }, [signOut]);

    return (
        <div className="landing">
            <p style={{ marginBottom: '20px' }}>{user?.email}</p>

            <h4 style={{ marginBottom: '7px' }}>Bookmarked destinations</h4>
            {data?.destinations.map((destination) => (
                <p key={destination.hash}>{destination.name}</p>
            ))}
            <button style={{ marginTop: '50px' }} onClick={onSignOut}>
                Log out
            </button>
        </div>
    );
};

export default UserProfile;
