import React, { useCallback } from 'react';
import './NeighborhoodCard.css';

// Note: imageUrl is possibly undefined
function NeighborhoodCard({ city, description, bookUrl }) {
    //TODO(basicNew): ideally this should be a link styled as a button
    const onBookClick = useCallback(() => window.open(bookUrl, '_blank'), [bookUrl]);

    return (
        <div className="neighborhood-card">
            <h3>{city}</h3>
            <p>{description}</p>
            <button style={{ backgroundColor: '#d3e96b', width: '100%', marginTop: '10px' }} onClick={onBookClick}>
                {' '}
                Book
            </button>
        </div>
    );
}

export default NeighborhoodCard;
