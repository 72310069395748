import useSWR from 'swr';
import { IoChevronBackCircle, IoChevronForwardCircle, IoCloseCircle, IoHeartCircle } from 'react-icons/io5';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';

import { backendGET, backendPOST } from '../utils';
import ExploreDestinationCard from './ExploreDestinationCard';
import { useAuth } from '../contexts/UserContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ReviewExploreDestinations.css';

const ReviewExploreLocations = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const browserLocation = useLocation();
    const { user, setOnLoginRedirect, session } = useAuth();

    const { data, mutate } = useSWR(session ? '/bookmarked-destinations' : null, () =>
        backendGET('bookmarked-destinations', { session })
    );

    const { destinations: baseDestinations, categories } = state || { destinations: [], categories: [] };

    const [destinations, setDestinations] = useState(
        baseDestinations.map((destination) => ({ ...destination, url: undefined }))
    );

    useEffect(() => {
        if (data?.destinations) {
            const bookmarkedHashes = new Set(data.destinations.map((destination) => destination.hash));
            setDestinations((destinations) =>
                destinations.map((destination) => {
                    if (bookmarkedHashes.has(destination.hash)) {
                        return { ...destination, bookmarked: true };
                    } else {
                        return { ...destination, bookmarked: false };
                    }
                })
            );
        }
    }, [data]);

    // Un-comment for quick iteration on the UI
    /*
    const placeholderLocations = [
        {
            name: 'Khiva, Uzbekistan',
            description: 'An ancient city along the Silk Road with well-preserved historical architecture.',
            url: 'https://replicate.delivery/pbxt/LmJ0CegFZPWqVSz8LeEsm2NyhKaWJe6ifpeLSfeLRWZJrMUcJA/out-0.png',
            bestTimeToVisit: 'April to June, September to October',
            topActivities: [
                'Explore the Ichon-Qala citadel',
                'Visit the Kalta-Minor Minaret',
                'Experience the Tash Hauli Palace',
                'Stroll through the Pakhlavan Mahmoud Mausoleum',
                'Wander around the Djuma Mosque and Minaret',
            ],
        },
        {
            name: 'Bucovina, Romania',
            description:
                'Known for its painted monasteries and stunning natural landscapes in the Carpathian Mountains.',
            url: 'https://replicate.delivery/pbxt/HtZ32U09zLaKD1HbDkBppY54WeCpA9J9PvmvTEqknaZqMUcJA/out-0.png',
            bestTimeToVisit: 'May to September',
            topActivities: [
                'Admire the iconic painted monasteries',
                'Hike through the picturesque Rarau and Giumalau Mountains',
                'Visit the Cacica Salt Mine',
                'Explore the cultural heritage of Suceava',
                'Enjoy traditional Romanian cuisine in local restaurants',
            ],
        },
        {
            name: 'Mani Peninsula, Greece',
            description:
                'A rugged and remote region in southern Greece known for its traditional stone tower-houses and pristine beaches.',
            url: 'https://replicate.delivery/pbxt/vKrZE7lxreWHcyo7Oe0dp3nzpQeW50TEJMQZjnFCRn1MzQxlA/out-0.png',
            bestTimeToVisit: 'June to September',
            topActivities: [
                'Hike the Viros Gorge',
                'Visit the Byzantine churches of Mystras',
                'Relax on the beaches of Limeni and Gerolimenas',
                'Explore the caves of Diros',
                'Taste the local specialty kagiana in tavernas',
            ],
        },
        {
            name: 'Labuan Bajo, Indonesia',
            description: 'Gateway to the Komodo National Park, famous for its Komodo dragons and vibrant marine life.',
            url: 'https://replicate.delivery/pbxt/UIAtde5siBSVOSUkkTc4xa8VonuOdJL2UVNJ4rgfNcKkZo4SA/out-0.png',
            bestTimeToVisit: 'April to June, September to November',
            topActivities: [
                'Visit Komodo Island to see the Komodo dragons',
                'Dive or snorkel in the crystal-clear waters of Pink Beach',
                'Trek to the scenic Padar Island viewpoint',
                'Explore the Batu Cermin Cave',
                "Relax on Kanawa Island's white sandy beach",
            ],
        },
        {
            name: 'Aitutaki, Cook Islands',
            description:
                'A pristine paradise with turquoise lagoons, coral reefs, and overwater bungalows for a luxurious escape.',
            url: 'https://replicate.delivery/pbxt/pRR8jH618k4VBxxbvbWHd4NlAMDE4q9971dtT1hpTJAaGKuE/out-0.png',
            bestTimeToVisit: 'May to October',
            topActivities: [
                'Take a lagoon cruise and snorkel in the crystal-clear waters',
                'Picnic on One Foot Island',
                'Enjoy a traditional umukai feast with locals',
                'Swim in the Aitutaki Lagoon',
                "Relax on the sandy beaches of O'otu and Akaiami",
            ],
        },
        {
            name: 'Valle de Guadalupe, Mexico',
            description: "Mexico's wine country, known for its vineyards, wineries, and innovative culinary scene.",
            url: 'https://replicate.delivery/pbxt/p78TYeJg22TvXCPJRtxq9BCdIc5GQszXSvI9UCUH9wowMUcJA/out-0.png',
            bestTimeToVisit: 'March to June, September to November',
            topActivities: [
                'Wine tasting at local vineyards',
                "Dine at award-winning restaurants like Deckman's en el Mogor",
                'Visit La Cava de Marcelo for cheese and artisanal bread',
                'Experience a temazcal ceremony for spiritual healing',
                'Explore the Ruta del Vino for scenic drives',
            ],
        },
        {
            name: 'Kep, Cambodia',
            description:
                'A tranquil seaside town famous for its crab market, charming villas, and laid-back atmosphere.',
            url: 'https://replicate.delivery/pbxt/zeGfV7OtizsoK0fRJHmx6YbK9w0GwfsXNVztbDShwsk0lhiLB/out-0.png',
            bestTimeToVisit: 'November to February',
            topActivities: [
                'Feast on fresh seafood at the Kep Crab Market',
                'Explore the abandoned French colonial villas',
                'Visit Kep National Park for jungle trekking',
                'Relax on the idyllic Rabbit Island',
                'Try the local specialty dish of Kep pepper crab',
            ],
        },
        {
            name: 'Tongariro National Park, New Zealand',
            description:
                'Home to three active volcanoes, stunning landscapes, and the famous Tongariro Alpine Crossing hike.',
            url: 'https://replicate.delivery/pbxt/W4pw018TzX77HVFMqrdiKs8awzd5KdJIAQC5QAWft9ntMUcJA/out-0.png',
            bestTimeToVisit: 'October to April',
            topActivities: [
                'Hike the Tongariro Alpine Crossing',
                'Visit the Emerald Lakes and Blue Lake',
                'Ski or snowboard on Mount Ruapehu in winter',
                'Explore the Taranaki Falls Walk',
                'Marvel at the volcanic terrain of the park',
            ],
        },
        {
            name: 'Bukhara, Uzbekistan',
            description:
                'An ancient city with well-preserved mosques, madrasas, and trading domes along the Silk Road.',
            url: 'https://replicate.delivery/pbxt/c7BXzHhitwafSKDWnSMwxf8OgKIMQsb6kEfzbGa6IxwwyQxlA/out-0.png',
            bestTimeToVisit: 'April to June, September to October',
            topActivities: [
                'Visit the Po-i-Kalyan complex',
                'Explore the Ark Fortress',
                'Shop for souvenirs at the Bukhara Grand Bazaar',
                'Enjoy traditional Uzbek cuisine in local restaurants',
                'Discover the Ismail Samani Mausoleum',
            ],
        },
        {
            name: 'Lake Ohrid, North Macedonia',
            description:
                'A UNESCO World Heritage Site with a historic old town, crystal-clear lake waters, and stunning mountain views.',
            url: 'https://replicate.delivery/pbxt/g5MtUZCRoeQeOkZZfaOV3i8muT4CdjZjCfk1tXdIyMl9lhiLB/out-0.png',
            bestTimeToVisit: 'May to September',
            topActivities: [
                'Explore the Old Town of Ohrid with its churches and museums',
                'Take a boat tour on Lake Ohrid to see St. Naum Monastery',
                "Hike up to the ancient Samoil's Fortress for panoramic views",
                'Visit the Bay of Bones Museum',
                'Swim in the refreshing waters of Lake Ohrid',
            ],
        },
    ];

    destinations = placeholderLocations;
    */

    // On mount, trigger to fetch images from the backend. Given how long things are taking to generate images
    // (and the fact they are not real location images) we will most likely change this in the near future
    useEffect(() => {
        const fetchImages = async () => {
            baseDestinations.map(async (destination, index) => {
                try {
                    if (destinations[index].url === undefined) {
                        const params = {
                            location: destination.name,
                            description: destination.description,
                            extent: { width: 512, height: 640 },
                        };
                        const { imageUrl } = await backendPOST('generate-location-image', params, {
                            retryOnHerokuTimeout: true,
                            maxRetries: 5,
                        });

                        setDestinations((prevData) => {
                            const newData = [...prevData];
                            const location = { ...newData[index], url: imageUrl };
                            newData[index] = location;
                            return newData;
                        });
                    }
                } catch (e) {
                    console.error(`Failed to fetch image for destination`, destination, e);
                }
            });
        };
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseDestinations]);

    const [index, setIndex] = useState(0);

    const handlePrev = () => {
        if (index > 0) {
            sliderRef.slickPrev();
            setIndex(index - 1);
        }
    };

    const handleNext = () => {
        if (index < destinations.length - 1) {
            sliderRef.slickNext();
            setIndex(index + 1);
        }
    };

    const currentDestination = useMemo(() => destinations[index], [destinations, index]);

    const bookmarked = useMemo(() => currentDestination?.bookmarked === true, [currentDestination]);

    const removeDestination = useCallback(() => {}, []);

    const toggleLikeDestination = useCallback(async () => {
        if (!user) {
            setOnLoginRedirect(browserLocation);
            navigate('/login');
        } else {
            const destinations = await backendPOST(
                'bookmark-destination',
                { destination: currentDestination, unset: currentDestination.bookmarked },
                { session }
            );
            mutate(destinations);
        }
    }, [user, setOnLoginRedirect, browserLocation, navigate, mutate, session, currentDestination]);

    let sliderRef;

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (currentIndex) => {
            setIndex(currentIndex);
        },
    };

    return (
        <div className="explore-container">
            <h1>Destinations for you</h1>
            <h2>Select the destinations you are curious about to create a trip.</h2>

            <div style={{ position: 'relative', width: '100%', maxWidth: '980px' }}>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                        {destinations.map((destination, idx) => (
                            <ExploreDestinationCard
                                key={idx}
                                name={destination.name}
                                description={destination.description}
                                imageUrl={destination.url}
                                bestTimeToVisit={destination.bestTimeToVisit}
                                activities={destination.topActivities}
                                categories={{ categories }}
                            />
                        ))}
                    </Slider>
                </div>
                <div className="explore-location-button-container">
                    <IoChevronBackCircle
                        size={48}
                        onClick={handlePrev}
                        style={{
                            visibility: index === 0 ? 'hidden' : 'visible',
                        }}
                    />
                    <div className="button-container" style={{ justifyContent: 'center' }}>
                        <IoCloseCircle
                            size={48}
                            style={{ marginRight: '10px', color: 'gray' }}
                            onClick={removeDestination}
                        />
                        <IoHeartCircle
                            size={48}
                            style={{ marginLeft: '10px', color: bookmarked ? 'var(--primary)' : 'white' }}
                            onClick={toggleLikeDestination}
                        />
                    </div>
                    <IoChevronForwardCircle
                        size={48}
                        onClick={handleNext}
                        style={{
                            visibility: index === destinations.length - 1 ? 'hidden' : 'visible',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewExploreLocations;
