import React, { createContext, useCallback, useEffect, useState, useContext } from 'react';
import { AuthClient } from '../clients/supabase';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(session?.user ?? null);
    const [redirectLocation, setRedirectLocation] = useState(null);
    const [checkingSession, setCheckingSession] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const initSession = async () => {
            setCheckingSession(true);
            try {
                const { data, error } = await AuthClient.getSession();
                if (!error && data.session) {
                    setSession(data.session);
                    setUser(data.session?.user ?? null);
                }
            } finally {
                setCheckingSession(false);
            }
        };

        initSession();

        const { data: authListener } = AuthClient.onAuthStateChange(async (event, session) => {
            setSession(session);
            setUser(session?.user ?? null);

            if (event === 'SIGNED_OUT') {
                navigate('/');
            }
            if (event === 'SIGNED_IN' && redirectLocation) {
                if (redirectLocation.state) {
                    navigate(redirectLocation, { state: redirectLocation.state });
                } else {
                    navigate(redirectLocation);
                }
            }
        });

        return () => {
            authListener?.subscription.unsubscribe();
        };
    }, [redirectLocation, navigate]);

    const signOut = useCallback(() => {
        AuthClient.signOut();
    }, []);

    const setOnLoginRedirect = useCallback((location) => {
        if (location?.pathname !== '/login') {
            setRedirectLocation(location);
        }
    }, []);

    return (
        <UserContext.Provider value={{ checkingSession, user, signOut, setOnLoginRedirect, session }}>
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = () => useContext(UserContext);

export default UserProvider;
