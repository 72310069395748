import React from 'react';
import LoadingDots from '../components/loadingdots';

const LoadingPage = () => {
    return (
        <div className="landing">
            <LoadingDots displayState={true} />
        </div>
    );
};

export default LoadingPage;
