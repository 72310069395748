import React from 'react';
import TripForm from './TripForm';
import TravelGrid from '../layouts/grids/TravelGrid';
import { GridItem } from '../layouts/grids/GridStyles';
import ReviewTripActivityCard from './ReviewTripActivityCard';

import { DEMO_DATA } from '../config';
import { getViatorUrl } from '../utils';

const Trip = () => {
    return (
        <div className="landing">
            <TripForm></TripForm>
            <div style={{ backgroundColor: 'white' }}>
                <TravelGrid>
                    {DEMO_DATA.map((activity) => {
                        const { name, description, imageUrl } = activity;
                        const viatorUrl = getViatorUrl(name);
                        return (
                            <GridItem key={name}>
                                <ReviewTripActivityCard
                                    key={name}
                                    name={name}
                                    description={description}
                                    exploreUrl={viatorUrl}
                                    imageUrl={imageUrl}
                                ></ReviewTripActivityCard>
                            </GridItem>
                        );
                    })}
                </TravelGrid>
            </div>
        </div>
    );
};

export default Trip;
