import React from 'react';
import Avatar from './Avatar';

import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <div className="header-left"></div>
            <div className="header-center">
                <h1 style={{ color: 'white', fontSize: '1.3rem', marginTop: '10px' }}>Travel Pal - Early version</h1>
            </div>
            <div className="header-right">
                <Avatar />
            </div>
        </div>
    );
};

export default Header;
