import { Auth } from '@supabase/auth-ui-react';
import { SupabaseClient } from '../clients/supabase';

import './Authenticate.css';

const Authenticate = () => {
    return (
        <div className="landing">
            <Auth
                id="auth-widget"
                supabaseClient={SupabaseClient}
                redirectTo={window.location.origin}
                appearance={{
                    extend: true,
                    className: {
                        container: 'auth-container',
                        input: 'auth-input',
                    },
                }}
                providers={[]}
            />
        </div>
    );
};

export default Authenticate;
