import { getUrl } from './config';

const defaultFetchOptions = {
    method: 'GET',
    retryOnHerokuTimeout: false,
    maxRetries: 0,
    delayBetweenRetries: 0,
    session: undefined,
};

// Skip the `method` property
const { method: _, ...defaultShortFetchOptions } = defaultFetchOptions;

/**
 * Perform an opinionated network fetch: we assume JSON params in the request body and a JSON response from the server
 *
 * @param path The path to fetch. We complete the full url of our server based on the configured host.
 * @param jsonParams Optional. If present, the params are encoded as JSON in the request body
 * @param options Optional. Fetch method as well as retry params in case of server timeout (Note: Heroku will kill any
 *                request that takes longer than 30 seconds. While this is reasonable for most "standard" calls,
 *                it is a pain for calls we need to make to Chat GPT or Replicate. Ideally we should set up an async
 *                workflow to handle these long-running jobs, but we want to keep things simple for the time being,
 *                so retry-on-timeout it is).
 * @returns The JSON response or an error in case the response is not ok
 */
export async function backendFetch(path, jsonParams = undefined, options = defaultFetchOptions) {
    const params = {
        method: options.method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (options?.session?.access_token) {
        params.headers = {
            ...params.headers,
            Authorization: `Bearer ${options.session.access_token}`,
        };
    }

    if (jsonParams !== undefined) {
        params.body = JSON.stringify(jsonParams);
    }

    let retriesLeft = options.maxRetries;
    while (true) {
        const response = await fetch(getUrl(path), params);
        if (response.ok) {
            return await response.json();
        } else {
            console.error(response);
            if (response.status === 503 && options.retryOnHerokuTimeout && retriesLeft > 0) {
                retriesLeft = retriesLeft - 1;
                console.error(`Retrying call, ${retriesLeft} attempts remaining`);
                await sleep(options.delayBetweenRetries);
            } else {
                throw new Error(`Network call failed with status ${response.status}`);
            }
        }
    }
}

export async function backendGET(path, options = defaultShortFetchOptions) {
    const fetchOptions = { ...defaultShortFetchOptions, ...options, method: 'GET' };
    return backendFetch(path, undefined, fetchOptions);
}

export async function backendPOST(path, jsonParams, options = defaultShortFetchOptions) {
    const fetchOptions = { ...defaultShortFetchOptions, ...options, method: 'POST' };
    return backendFetch(path, jsonParams, fetchOptions);
}

export function sleep(delayMS) {
    return new Promise((resolve) => {
        setTimeout(resolve, delayMS);
    });
}

export function getViatorUrl(location) {
    const locationName = location.toLowerCase().trim();
    return (
        `https://www.viator.com/searchResults/all?text=${encodeURIComponent(locationName)}` +
        '&destId=&pid=P00093833&mcid=42383&medium=link&campaign=travelpal-v0'
    );
}
