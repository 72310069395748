import React, { useState, useCallback } from 'react';
import { InputLabel, InputUnderline } from './Input';
import { backendPOST } from '../utils';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../components/loadingdots';

import './TripForm.css';

const TripForm = () => {
    const [location, setLocation] = useState('San Francisco');
    const [isSearching, setIsSearching] = useState(false);
    const [budget, setBudget] = useState('1000');
    const [vibes, setVibes] = useState('Nature, Adventure, Relaxing');
    const [occasion, setOccasion] = useState('Solo trip');
    const [numberOfDays, setNumberOfDays] = useState('3');

    const navigate = useNavigate();

    const generateTrip = useCallback(async () => {
        setIsSearching(true);
        try {
            const tripParameters = {
                location: location,
                budget: budget,
                vibes: vibes,
                numberOfDays: numberOfDays,
                occasion: occasion,
            };
            const trip = await backendPOST(
                'generate-trip',
                { parameters: tripParameters },
                {
                    retryOnHerokuTimeout: true,
                    maxRetries: 5,
                }
            );
            navigate('/review-trip', { state: { trip: { ...trip, parameters: tripParameters } } });
        } finally {
            setIsSearching(false);
        }
    }, [location, budget, vibes, numberOfDays, occasion, navigate]);

    return (
        <div className="form-container">
            <h1>Travel Pal</h1>
            <div className="input-container">
                <div className="input-wrapper">
                    <InputUnderline
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="San Francisco"
                        breakText
                    />
                    <InputLabel>Destination</InputLabel>
                </div>
                <div className="input-wrapper">
                    <InputUnderline onChange={(e) => setNumberOfDays(e.target.value)} placeholder="3" />
                    <InputLabel>
                        Duration
                        <span>(Days)</span>
                    </InputLabel>
                </div>
                <div className="input-wrapper">
                    <InputUnderline onChange={(e) => setBudget(e.target.value)} placeholder="1000" />
                    <InputLabel>
                        Budget
                        <span>($)</span>
                    </InputLabel>
                </div>
                <div className="input-wrapper">
                    <InputUnderline onChange={(e) => setOccasion(e.target.value)} placeholder="Solo Trip" />
                    <InputLabel>Occasion</InputLabel>
                </div>
                <div className="input-wrapper">
                    <InputUnderline
                        onChange={(e) => setVibes(e.target.value)}
                        placeholder="Nature, adventure, relaxing"
                        breakText
                    />
                    <InputLabel>Vibes</InputLabel>
                </div>
            </div>
            <button onClick={generateTrip}>
                {isSearching ? <LoadingDots displayState={true} /> : 'Create my trip'}
            </button>
        </div>
    );
};

export default TripForm;
