export const SERVER_URL = process.env.REACT_APP_BACKEND_HOST;
export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
export const SUPABASE_PUBLIC_KEY = process.env.REACT_APP_SUPABASE_PUBLIC_KEY;

export function getUrl(slug) {
    return `${SERVER_URL}/${slug}`;
}

//before user clicks "createMyTrip"
export const DEMO_DATA = [
    {
        id: 0,
        imageUrl:
            'https://images.unsplash.com/photo-1595790588353-8181fb7ee071?q=80&w=800&h=550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Costa Brava, Spain',
        description: `5 days | Romantic Getaway | Cala, Swimming, Small Towns`,
        parameters: {
            location: 'Costa brava, spain',
            budget: '1000',
            vibes: 'Cala, Swimming, Small Towns',
            occasion: 'Romantic getaway',
            numberOfDays: '5',
        },
    },
    {
        id: 1,
        imageUrl:
            'https://images.unsplash.com/photo-1588475879026-2eb2d03d591c?q=80&w=800&h=550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Lake Bled, Slovenia',
        description: `2 days | Weekend Trip | Fairytale, Adventurous, Dreamy`,
        parameters: {
            location: 'Lake Bled, Slovenia',
            budget: '1000',
            vibes: 'Fairytale, Adventurous, Dreamy',
            occasion: 'Weekend Trip',
            numberOfDays: '2',
        },
    },
    {
        id: 2,
        imageUrl:
            'https://images.unsplash.com/photo-1562195168-c82fea0f0953?q=80&w=800&h=550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Sintra, Portugal',
        description: `3 days | Friend Trip | Historical, Outdoors, Palaces`,
        parameters: {
            location: 'Sintra, Portugal',
            budget: '1000',
            vibes: 'Historical, Outdoors, Palaces',
            occasion: 'Friend Trip',
            numberOfDays: '3',
        },
    },
    {
        id: 3,
        imageUrl:
            'https://images.unsplash.com/photo-1490806843957-31f4c9a91c65?q=80&w=800&h=550&auto=format&fit=cropixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Japan',
        description: `3 days | Family Trip | Unique, Luxury, Nature`,
        parameters: {
            location: 'Japan',
            budget: '1000',
            vibes: 'Unique, Luxury, Nature',
            occasion: 'Family Trip',
            numberOfDays: '3',
        },
    },
    {
        id: 4,
        imageUrl:
            'https://images.unsplash.com/photo-1553913861-c0fddf2619ee?q=80&w=800&h=550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Egypt',
        description: `7 days | Friends Reunion | Historic, Touristy`,
        parameters: {
            location: 'Egypt',
            budget: '2000',
            vibes: 'Historic, Touristy',
            occasion: 'Friends Reuion',
            numberOfDays: '7',
        },
    },
    {
        id: 5,
        imageUrl:
            'https://images.unsplash.com/photo-1602216056096-3b40cc0c9944?q=80&w=800&h=550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        name: 'Kerala',
        description: `1 day | Solo Adventure | Meditative, Relaxing`,
        parameters: {
            location: 'Kerala, India',
            budget: '1000',
            vibes: 'Meditative, Relaxing',
            occasion: 'Solo Adventure',
            numberOfDays: '1',
        },
    },
];

export const exploreImages = [
    {
        id: 1,
        category: 'cottage',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/50zziko8clmR4fmlF2pDgX/9b0ae16100fb1edb0260878745b7a2a7/camille-brodard-sM3Wb7HAFHY-unsplash.jpg',
        alt: 'cottage',
    },
    {
        id: 2,
        category: 'cottage',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2oj09vckNgp1m7Sn2XQPjl/5b85a82555d6587037456fcbe9daf056/jantje-RiO6bk3DbAM-unsplash.jpg',
        alt: 'cottage',
    },
    {
        id: 3,
        category: 'cottage',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/72tAnJtW9gkI7smkcjXIQg/0bea583d0e719263dceb7b25d3efd528/camille-brodard-kOAU9SG0VX8-unsplash.jpg',
        alt: 'cottage',
    },
    {
        id: 4,
        category: 'cottage',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3wFa2UJEtC0C0eHNqDhszc/8ac75bdbb98e5f728c8d030fd06d6143/camille-brodard-Ejhi-9XdSUc-unsplash_2.jpg',
        alt: 'cottage',
    },
    /* Temporarily commenting the "vapor" category, as that makes the ravel guide hallucinate
    {
        id: 5,
        category: 'vapor',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2whOzlmzVIAbqJCxeFybP4/103115884431a72cb7b9f5e4fbe9eb67/alek-kalinowski-Sj_3Jdr19L4-unsplash.png',
        alt: 'vapor',
    },
    {
        id: 6,
        category: 'vapor',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/TJS7hKAH5u6meNPC0OPDz/29c6988a2abf40024ee15b42b334c24b/amber-kipp-bJrqQwGM3cI-unsplash.png',
        alt: 'vapor',
    },
    {
        id: 7,
        category: 'vapor',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/6DuzovetKyf2AddULU8zr0/06dffbbf6a3cd2953064c3ae930ac4de/cheng-feng-psdV2Rl-GvU-unsplash.png',
        alt: 'vapor',
    },
    {
        id: 8,
        category: 'vapor',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7MNSZ2DnQ36K7lLdT673zS/2bc727c7e7e3f3e4827f8ce45621cec4/efe-kurnaz-RnCPiXixooY-unsplash.png',
        alt: 'vapor',
    },
    */
    {
        id: 9,
        category: 'rebel',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/114ucEdIV1dzjg79FuaJ9F/832f714356f9f1be5a600a474112d9bd/li-yang-ZlveB8hwj4c-unsplash.png',
        alt: 'rebel',
    },
    {
        id: 10,
        category: 'rebel',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7msXsJtVng9o1PCBWEK9ek/843f2d0953c72283dbb0dd9232bd001d/neom-gOqBe7ropxM-unsplash.png',
        alt: 'rebel',
    },
    {
        id: 11,
        category: 'rebel',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4CUgZ8MYCAGmvTHZ4TZRcL/4e92ba302d666e2dc8f172e76478c8de/neom-yg6v0KoiIcU-unsplash.png',
        alt: 'rebel',
    },
    {
        id: 12,
        category: 'rebel',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7tkHfREpAgsKUl7XaOxDlS/354ef522d3c7fcb34721979af44ee6cc/parker-hilton-VtGLcivTXtk-unsplash.png',
        alt: 'rebel',
    },
    {
        id: 13,
        category: 'dark_academia',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/16mkp8qgUH4ttMuIJWO0L9/81abd899fd3f274a8f7276524943b14d/darran-shen-x9xYNkYjyYY-unsplash.png',
        alt: 'dark_academia',
    },
    {
        id: 14,
        category: 'dark_academia',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/5J83020eTCjUGb4LAO08jc/e754d081707cbf6cac46e279f1482f9c/chad-greiter--0gBnnMdQPw-unsplash.png',
        alt: 'dark_academia',
    },
    {
        id: 15,
        category: 'dark_academia',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1ZLHvNVpYLeHFr9t79FMt5/63ae873c3f482478b2e8c541cbc045af/jez-timms-8muUTAmcWU4-unsplash.png',
        alt: 'dark_academia',
    },
    {
        id: 16,
        category: 'dark_academia',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3h6JMwlxGakSh0fJvJHzVU/a81d7e8c506ae438d5975235b3a851ed/javier-grixo-p1opmw12wvk-unsplash.png',
        alt: 'dark_academia',
    },
    {
        id: 17,
        category: 'fairy',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4ldSeU6TBB3DBZAExdI1Rh/5c858c0ff3facc312705b97991e141b1/jeff-finley-bK8Wlaq7NaA-unsplash.png',
        alt: 'fairy',
    },
    {
        id: 18,
        category: 'fairy',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3EZJLm6fcoBT0V4mOqMuf1/b52c7680af1172915f530fbfb9aca86b/cederic-vandenberghe-21DP3hytVHw-unsplash.png',
        alt: 'fairy',
    },
    {
        id: 19,
        category: 'fairy',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3TBBT9HQNlbmGXSUmP0wK7/d7cb5ffcf384900f88121fe480e47900/michael-krahn-eGD69I3ODC4-unsplash.png',
        alt: 'fairy',
    },
    {
        id: 20,
        category: 'fairy',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/hwpWVIXnkpbt9fMZdADt1/a144554189c0537428630b245c80a9f0/peter-herrmann-xZfdoXQf1VE-unsplash.png',
        alt: 'fairy',
    },
    {
        id: 21,
        category: 'globe',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/rSe8kE6FC63NbDbmCEABV/dd69741fb1131575fe367df3d783c74e/ryoji-iwata-n31JPLu8_Pw-unsplash.png',
        alt: 'globe',
    },
    {
        id: 22,
        category: 'globe',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/378qTd7vr1mXzeuxNJeeGk/5e9ca741d2f978c0f70ee02207b4ed59/markus-spiske-n7ZyyFeNDSc-unsplash.png',
        alt: 'globe',
    },
    {
        id: 23,
        category: 'globe',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/6CT3okWEBNNZGSp7yCVXMU/7a4b471ddb2a297fd30e3bf074bc96f3/rod-long-2P_ifaetDm0-unsplash.png',
        alt: 'globe',
    },
    {
        id: 24,
        category: 'globe',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2p6CGNaoHEP10IGApbmt6I/4917f88f7a8c3f52887dffcaba74f39f/lachlan-gowen-RZ5TKFpdaWM-unsplash.png',
        alt: 'globe',
    },
    {
        id: 25,
        category: 'art',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4C8e7pbDcUImh4HqoemrbM/d70db97459b6252627297861f4b51df5/andrew-neel-acowe0pCVBg-unsplash.png',
        alt: 'art',
    },
    {
        id: 26,
        category: 'art',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3GgcLwMdzDFr4OzUfFLQwv/fba4c2efaa8260fb159621fb051a1da3/alex-simpson-2FUTIujX8fM-unsplash.png',
        alt: 'art',
    },
    {
        id: 27,
        category: 'art',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/10vcpf8qLQAaaysPE8qgZe/97908d766560ed66f972a7dda5fdaea3/estee-janssens-MUf7Ly04sOI-unsplash.png',
        alt: 'art',
    },
    {
        id: 28,
        category: 'art',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4hh0QwbdJspasgAScZsqZT/1bfa0f14d990b435ef387478749af9e3/diogo-fagundes-XvQkiEkLrss-unsplash.png',
        alt: 'art',
    },
    {
        id: 29,
        category: 'old_money',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/31Y8xwXyyWMHTNOcWzL9jn/ab82b79103c32f36eccd55846e3a43d6/sam-pearce-warrilow-ARrAT1tX9wE-unsplash.png',
        alt: 'old_money',
    },
    {
        id: 30,
        category: 'old_money',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/59UCqfJwuVth0AGmoPdHjm/3c5b7c9efce7fcf0f9f9711d7b900397/stories--BqB2zptPQo-unsplash.png',
        alt: 'old_money',
    },
    {
        id: 31,
        category: 'old_money',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1bIJqGIfjPz1aLVsCTdBk5/ba2cc0f2cef22a3850dd9f8fc7043b85/nicola-ripepi-W259Jp-dcsM-unsplash.png',
        alt: 'old_money',
    },
    {
        id: 32,
        category: 'old_money',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7pTjV06mQBUJ8hwUotOEAb/42599a88d1391d30cb79d09eb2f93a63/joshua-woods-v5C1DBtD59o-unsplash.png',
        alt: 'old_money',
    },
    {
        id: 33,
        category: 'retro',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1xjufukZcOHbRCSvwcy3SG/cbc6df6f64c9d4a42d6ec7c7928b0997/ajeet-mestry-UBhpOIHnazM-unsplash.png',
        alt: 'retro',
    },
    {
        id: 34,
        category: 'retro',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/U7acbwCI9nh0yy3fVKF0m/1f9e524061b690c937d71ee5bea9fc58/erik-mclean-QzpgqElvSiA-unsplash.png',
        alt: 'retro',
    },
    {
        id: 35,
        category: 'retro',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7Al1GzUjKQ29cY2TDESRj0/2b2430c4bc5813c0247475f979722be6/steve-gale-yXHE8JRRQ0w-unsplash.png',
        alt: 'retro',
    },
    {
        id: 36,
        category: 'retro',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1jCbD7IqhVaHJoQhTeQ9qV/76185b8de5458b7f9c8fac9356f32c12/hans-vivek-sLPRSdxsMUA-unsplash.png',
        alt: 'retro',
    },
    {
        id: 37,
        category: 'avant',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3z1edTQMF4FT11OfId931a/78c29967b7c81ad598f20b96bc42fbd7/hong-nguyen-FO-zQd7Wqio-unsplash.png',
        alt: 'avant',
    },
    {
        id: 38,
        category: 'avant',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4w5lj2hErVowAzes1Le55V/0e61409ca3c3d8b6d8af0279ac05a670/ambrose-chua-zxbNbuncq1g-unsplash.png',
        alt: 'avant',
    },
    {
        id: 39,
        category: 'avant',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/7d4VdfezMBG3lhuvpG4yiR/9238bb0c6a2a215bf539c5962b429bb3/trinh-minh-th-twN2viFvWd0-unsplash.png',
        alt: 'avant',
    },
    {
        id: 40,
        category: 'avant',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/WGbuJiNGOtB3okCAZfjgr/0662a6eed6be76f7867d32e9899a5490/tanya-layko-QINaeQQHghQ-unsplash.png',
        alt: 'avant',
    },
    {
        id: 41,
        category: 'intellect',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1cNoIULnZSsdMS0Ukv7P8o/00d930001b993912cbdee6f0c6f24639/takahiro-taguchi-Qa-L33FnQ9o-unsplash.png',
        alt: 'intellect',
    },
    {
        id: 42,
        category: 'intellect',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/14CprtwLEHrb5t8TUtP9cJ/4261dd934e30d40f21a1b951555dc1e4/k-mitch-hodge-wZfndoCA1rA-unsplash.png',
        alt: 'intellect',
    },
    {
        id: 43,
        category: 'intellect',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1HPFvs1dfpE8Kg8k31oT3X/be1958ec01532de130521382a66af6dc/patrick-tomasso-Oaqk7qqNh_c-unsplash.png',
        alt: 'intellect',
    },
    {
        id: 44,
        category: 'intellect',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2AbKP8a1ohcuxe8xgk6QI/52d5c88e2b1fe66b3ca0eea86aad649f/anne-nygard-h7-V2KkCECI-unsplash.png',
        alt: 'intellect',
    },
    {
        id: 45,
        category: 'wellness',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4t6AgsDjWb465vnJ2bqTQ3/fe379d55b1847a6bc4f76c0004cf37d5/diana-light-pBBxMUCgwuU-unsplash.png',
        alt: 'wellness',
    },
    {
        id: 46,
        category: 'wellness',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/hP5XnB2GFMIpQcis3FbyK/6b082c1ed04e534b82f6a25d9c84c0d3/camille-brodard-AVi4lkHl5L4-unsplash.png',
        alt: 'wellness',
    },
    {
        id: 47,
        category: 'wellness',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/560pu7EgtTtshQpbx3obbG/0a056726c478878309cfe29f32ae12b3/magic-bowls-b08FP4cLpFw-unsplash.png',
        alt: 'wellness',
    },
    {
        id: 48,
        category: 'wellness',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/11cfMHFwj0GGAT01nRYt7r/7989df60347033db9fbf6f2b1eaa6e56/photoholgic-MqNyusb_ne4-unsplash.png',
        alt: 'wellness',
    },
    {
        id: 49,
        category: 'digital',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4859A0D7aQOn7tL0bbLSFg/425e8c11e0da9cc1fc6417c3de8f0dd1/maria-frances-8cCjjl6qK0A-unsplash.png',
        alt: 'digital',
    },
    {
        id: 50,
        category: 'digital',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1tbQU80Vux1Ba2RKPRkOK3/f825b02d17892dd570c5bb8d7681655a/jane-palash-SpvR3bbNJJw-unsplash.png',
        alt: 'digital',
    },
    {
        id: 51,
        category: 'digital',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2i5IUCa3T0nTfqTuzdUKQd/1ca732c572e1ea6a854c9a8862658cb5/tobias-tullius-cqRhyG4l2PU-unsplash.png',
        alt: 'digital',
    },
    {
        id: 52,
        category: 'digital',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2imrpN2vypSRhVCnMeCkRd/6e4f8ce82e59dfaa6fe859226710a4d2/yoav-aziz-ZVTeW5ln9QE-unsplash.png',
        alt: 'digital',
    },
    {
        id: 53,
        category: 'culture',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4BYwgaZE9QrsDXAkk1AowA/c5a5fd0f32a773ab91eb80fddec907e7/debashis-rc-biswas-dyPFnxxUhYk-unsplash.png',
        alt: 'culture',
    },
    {
        id: 54,
        category: 'culture',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4X1zw1dMNKEgB62SmABYmh/9be948381d1fd614c858a73c9678d2b0/samos-box-mXVA-m97kYo-unsplash.png',
        alt: 'culture',
    },
    {
        id: 55,
        category: 'culture',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/25ZqJaMdXrs1fX7Kweaihu/bbf9a1dc520a42c697063f545812110d/aman-shrestha-5NyUCREUHnk-unsplash.png',
        alt: 'culture',
    },
    {
        id: 56,
        category: 'culture',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/sBVGsNcFQ012Z3MUp90qE/86c96daefe1c9c0549284d61a101dfbc/mostafa-meraji-37C6QSp7Tco-unsplash.png',
        alt: 'culture',
    },
    {
        id: 57,
        category: 'luxurian',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/4J2SYcQnTWz2wKls6Vgzd4/e4970dfd0e3d511b1bb787c535765bd8/manuel-moreno-DGa0LQ0yDPc-unsplash.png',
        alt: 'luxurian',
    },
    {
        id: 58,
        category: 'luxurian',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/1bqdsemQStuxVgN1DCDn8M/db027e4d5f0e3996a816c8b60e28a4fb/shifaaz-shamoon-qtbV_8P_Ksk-unsplash.png',
        alt: 'luxurian',
    },
    {
        id: 59,
        category: 'luxurian',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/3rqDMhEeCI4wgNbEXLp7gF/31989c8ccbd6bc6c58f3b71a7942be39/anthony-delanoix-TkVy388Eopc-unsplash.png',
        alt: 'luxurian',
    },
    {
        id: 60,
        category: 'luxurian',
        url: 'https://images.ctfassets.net/f8uc5suwsd28/2LNrezBYvNZBFTtsdkX1xC/63880881ae9ab19fd751e203746d6f19/jessica-kantak-bailey-xndveMC26Rc-unsplash.png',
        alt: 'luxurian',
    },
];
