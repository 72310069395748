import React, { useCallback } from 'react';

const placeholder =
    'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=670&q=80';

// Note: imageUrl is possibly undefined
function ReviewTripActivityCard({ name, description, imageUrl, exploreUrl }) {
    const url = imageUrl ?? placeholder;

    //TODO(basicNew): ideally this should be a link styled as a button
    const onExploreClick = useCallback(() => window.open(exploreUrl, '_blank'), [exploreUrl]);

    return (
        <>
            <img src={url} alt={name} className="main-image" />
            <p>
                <span style={{ fontWeight: '800' }}>{name}</span>. {description}
            </p>
            <button
                style={{ backgroundColor: 'var(--primary)', color: 'black', width: '100%', marginTop: '10px' }}
                onClick={onExploreClick}
            >
                {' '}
                Explore
            </button>
        </>
    );
}

export default ReviewTripActivityCard;
