// import TagManager from 'react-gtm-module'; //https://www.plumfind.com/academy/how-to-implement-google-tag-manager-for-single-page-applications-such-as-react
//https://github.com/alinemorelli/react-gtm

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MobileLayout from './mobile/MobileLayout';
import MobileApp from './mobile/MobileApp';
import MobileExplore from './mobile/Explore';
import ReviewExploreDestinations from './mobile/ReviewExploreDestinations';
import ReviewTrip from './mobile/ReviewTrip';
import Authenticate from './mobile/Authenticate';
import UserProfile from './mobile/UserProfile';
import Trip from './mobile/Trip';
import UserProvider from './contexts/UserContext';

import PrivateRoutes from './PrivateRoutes';

import DeviceProvider from './contexts/DeviceContext';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <DeviceProvider>
            <UserProvider>
                <Routes>
                    {/*TODO(Andy): Finish porting trip generation to mobile and remove the legacy app */}
                    {/*TODO(Andy): When we do that we should move the context provider to the layout too */}
                    <Route element={<MobileLayout />}>
                        <Route path="/" element={<MobileApp />} />
                        <Route path="/login" element={<Authenticate />} />
                        <Route path="/trip" element={<Trip />} />
                        <Route path="/explore" element={<MobileExplore />} />
                        <Route path="/review-explore-destinations" element={<ReviewExploreDestinations />} />
                        <Route path="/review-trip" element={<ReviewTrip />} />
                        <Route element={<PrivateRoutes />}>
                            <Route path="/profile" element={<UserProfile />} />
                        </Route>
                    </Route>
                </Routes>
            </UserProvider>
        </DeviceProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
