import React from "react";
// import AutoContainer from "../AutoContainer";
import { GridInner, GridRow, GridWrapper, AutoContainer } from "./GridStyles";

const TravelGrid = ({ children }) => {
  return (
    <GridWrapper>
      <AutoContainer>
        <GridInner>
          <GridRow>{children}</GridRow>
        </GridInner>
      </AutoContainer>
    </GridWrapper>
  );
};

export default TravelGrid;
