import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
//import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReviewTripActivityCard from './ReviewTripActivityCard';
import TravelGrid from '../layouts/grids/TravelGrid';
import { backendPOST, getViatorUrl } from '../utils';
import { GridItem } from '../layouts/grids/GridStyles';
import NeighborhoodCard from './NeighborhoodCard';

import 'react-tabs/style/react-tabs.css';
import './ReviewTrip.css';

/*
TODO(Andy): Restore once we have maps back

const containerStyle = {
    width: '100vw',
    height: '100%',
};

// Just a placeholder
const center = {
    lat: 0,
    lng: 0,
};

*/

const ReviewTrip = () => {
    const { state } = useLocation();
    const { trip } = state || { trip: {} };
    const { parameters, activities: baseActivities } = trip;
    /*
    TODO(Andy): Restore once we have maps back

    const [map, setMap] = useState(null);
    const { isLoaded: isMapsLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAzXd7UERpOiemuNecnRu-LmQxQjob1NJc',
    });
    */

    const [activities, setActivities] = useState(() => {
        return baseActivities.map((activity) => {
            const { name } = activity;
            const url = getViatorUrl(name);
            return { ...activity, viatorUrl: url, imageUrl: undefined };
        });
    });

    const [placesToStay, setPlacesToStay] = useState(undefined);

    useEffect(() => {
        const fetchNeighborhood = async () => {
            const params = { parameters, activitiesList: baseActivities };
            const { locations } = await backendPOST('generate-location-recommendations', params);
            for (const location of locations) {
                const { name } = location;
                const locationName = `${name.toLowerCase().trim()}, ${parameters.location}`;
                location.bookUrl = `https://www.booking.com/searchresults.en-gb.html?ss=${encodeURIComponent(
                    locationName
                )}&aid=8032794`;
            }

            setPlacesToStay(locations);
        };
        fetchNeighborhood();
    }, [baseActivities, parameters]);

    const [itinerary, setItinerary] = useState(undefined);

    useEffect(() => {
        const fetchItinerary = async () => {
            const params = { parameters, activitiesList: baseActivities };
            const { message } = await backendPOST('generate-itinerary', params);
            //TODO(Andy): Get properly formatted answer
            const activityList = message.split('\n').map((activity) => activity.trim());
            const itinerary = activityList.filter((item) => item !== '');
            setItinerary(itinerary);
        };
        fetchItinerary();
    }, [baseActivities, parameters]);

    // On mount, trigger to fetch images from the backend. Given how long things are taking to generate images
    // (and the fact they are not real location images) we will most likely change this in the near future
    useEffect(() => {
        const fetchImages = async () => {
            baseActivities.map(async (activity, index) => {
                try {
                    if (activities[index].imageUrl === undefined) {
                        const params = {
                            prompt: `${activity.name}, ${activity.description}`,
                            extent: { width: 640, height: 512 },
                        };
                        const { imageUrl } = await backendPOST('generate-image', params, {
                            retryOnHerokuTimeout: true,
                            maxRetries: 5,
                        });
                        setActivities((prevData) => {
                            const newData = [...prevData];
                            const newActivity = { ...newData[index], imageUrl };
                            newData[index] = newActivity;
                            return newData;
                        });
                    }
                } catch (e) {
                    console.error(`Failed to fetch image for activity`, activity, e);
                }
            });
        };
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseActivities]);

    /*
    TODO(Andy): Restore once we have maps back

    useEffect(() => {
        if (map && mapMarkers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            mapMarkers.forEach(({ latitude, longitude }) => {
                bounds.extend({ lat: latitude, lng: longitude });
            });
            map.fitBounds(bounds);
        }
    }, [map, mapMarkers]);
    */

    return (
        <div
            className="explore-container"
            style={{
                height: '100vh',
                flexDirection: 'column',
                display: 'flex',
                padding: '0px',
            }}
        >
            {/*
            <div
                className="map-container"
                style={{
                    flex: '1',
                    display: 'flex',
                }}
            >

                TODO(Andy): replace with a free map service and/or subscribe to a paid one

                isMapsLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={2}
                        options={{
                            mapTypeControlOptions: {
                                mapTypeIds: ['ROADMAP', 'HYBRID'],
                            },
                        }}
                        onLoad={(mapInstance) => {
                            setMap(mapInstance);
                        }}
                    >
                        {map &&
                            mapMarkers.map((marker, index) => {
                                return (
                                    <MarkerF
                                        key={index}
                                        label={marker.name}
                                        position={{ lat: marker.latitude, lng: marker.longitude }}
                                    />
                                );
                            })}
                    </GoogleMap>
                        ) : undefined}
                        </div>*/}
            <div
                style={{
                    flex: '1',
                    padding: '20px',
                    boxSizing: 'border-box',
                    maxHeight: '100vh', // Make this 50vh when restoring the map above that uses the other 50% of the page
                    overflow: 'scroll',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    color: 'black',
                }}
            >
                <Tabs style={{ width: '100vw' }} focusTabOnClick={false}>
                    <TabList>
                        <Tab>Activities</Tab>
                        <Tab>Itinerary</Tab>
                        <Tab>Where to Stay</Tab>
                    </TabList>
                    <TabPanel>
                        <TravelGrid>
                            {activities.map((activity) => {
                                const { name, description, viatorUrl, imageUrl } = activity;
                                return (
                                    <GridItem key={name}>
                                        <ReviewTripActivityCard
                                            key={name}
                                            name={name}
                                            description={description}
                                            exploreUrl={viatorUrl}
                                            imageUrl={imageUrl}
                                        ></ReviewTripActivityCard>
                                    </GridItem>
                                );
                            })}
                        </TravelGrid>
                    </TabPanel>
                    <TabPanel>
                        <div style={{ padding: '10px' }}>
                            {itinerary === undefined
                                ? 'Loading'
                                : itinerary.map((item, index) => <p key={index}>{item}</p>)}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        {placesToStay === undefined
                            ? 'Loading'
                            : placesToStay.map((place) => {
                                  const { name, description, bookUrl } = place;
                                  return (
                                      <NeighborhoodCard
                                          key={name}
                                          city={name}
                                          description={description}
                                          bookUrl={bookUrl}
                                      ></NeighborhoodCard>
                                  );
                              })}
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

export default ReviewTrip;
