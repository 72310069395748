import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import MainMenu from './MainMenu';

import './MobileLayout.css';

const MobileLayout = () => {
    return (
        <div className="mobile-container">
            <Header></Header>
            <MainMenu></MainMenu>
            <Outlet />
        </div>
    );
};

export default MobileLayout;
