import React, { createContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export const Device = Object.freeze({
    MOBILE: { name: 'MOBILE', minWidth: 0, maxWidth: 767 },
    TABLET: { name: 'TABLET', minWidth: 768, maxWidth: 991 },
    DESKTOP: { name: 'DESKTOP', minWidth: 992, maxWidth: 1823 },
    BIG_SCREEN: { name: 'BIG_SCREEN', minWidth: 1824, maxWidth: 50000 },
});

const mediaQueryFor = (device) => {
    return { minWidth: device.minWidth, maxWidth: device.maxWidth };
};

export const DeviceContext = createContext();

const DeviceProvider = ({ children }) => {
    const isMobile = useMediaQuery(mediaQueryFor(Device.MOBILE));
    const isTablet = useMediaQuery(mediaQueryFor(Device.TABLET));
    const isDesktop = useMediaQuery(mediaQueryFor(Device.DESKTOP));
    const isBigScreen = useMediaQuery(mediaQueryFor(Device.BIG_SCREEN));

    const device = useMemo(() => {
        if (isMobile) {
            return Device.MOBILE.name;
        }
        if (isTablet) {
            return Device.TABLET.name;
        }
        if (isDesktop) {
            return Device.DESKTOP.name;
        }
        if (isBigScreen) {
            return Device.BIG_SCREEN.name;
        }
        return undefined;
    }, [isMobile, isTablet, isDesktop, isBigScreen]);

    return (
        <DeviceContext.Provider value={{ isMobile, isTablet, isDesktop, isBigScreen, device }}>
            {children}
        </DeviceContext.Provider>
    );
};

export default DeviceProvider;
