import React from 'react';
import './loadingdots.css';

function LoadingDots({ displayState }) {
    if (displayState) {
        return (
            <div className="divider-loading">
                <div className="loading">
                    <div className="loading-dot"></div>
                    <div className="loading-dot"></div>
                    <div className="loading-dot"></div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default LoadingDots;
